import React, { createContext, useCallback, useContext, useState } from "react";

export const ImageViewerContext = createContext();

const initialState = [];

function ImageViewerProvider(props) {
  const [images, setImages] = useState(initialState);
  const [isOpen, setIsOpen] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);

  const activeImage = images[activeIndex];

  const resetViewer = useCallback(() => {
    setImages(initialState);
  });

  const registerImage = useCallback((src) => {
    setImages(images => {
      if (!images.includes(src)) {
        return [...images, src];
      }
      return images
    });
  });

  const displayImage = useCallback((i) => {
    setIsOpen(true);
    setActiveIndex(i);
  });

  const closeImage = useCallback((i) => {
    setIsOpen(false);
  });

  const nextImage = () => {
    setActiveIndex(activeIndex => (activeIndex + 1) % images.length);
  };

  const previousImage = () => {
    setActiveIndex(activeIndex => (activeIndex - 1) % images.length);
  };

  const value = { images, isOpen, activeImage, nextImage, previousImage, resetViewer, registerImage, displayImage, closeImage };

  return <ImageViewerContext.Provider value={value} {...props} />;
}

function useImageViewerContext() {
  return useContext(ImageViewerContext);
}

export { ImageViewerProvider, useImageViewerContext };
