export const fetchGet = (url, params = {}) => {
    const query = new URLSearchParams(params).toString();
    return fetch(`${url}?${query}`).then(response => response.json());
}

export const fetchPost = (url, body, params = {}) => {
    const query = new URLSearchParams(params).toString();
    return fetch(`${url}?${query}`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify(body)
    }).then(response => response.json());
}