import React, { Suspense } from 'react';
import { Switch, Route } from 'react-router-dom';
import Navbar from './container/NavBar';
import Footer from './component/Footer';
import Loader from './component/Loader';
import { CmsDataWrapper } from 'context/cmsData';
// import ThingsToDoPage from './container/ThingsToDo';

const HomePage = React.lazy(() => import('./container/Home'));
const ThingsToDoTemplate = React.lazy(() => import('./container/ThingsToDo'));
// const AboutPage = React.lazy(() => import('./container/About'));
// const SpecialsPage = React.lazy(() => import('./container/Specials'));
// const WeddingsPage = React.lazy(() => import('./container/Weddings'));
// const RetreatsPage = React.lazy(() => import('./container/Retreats'));
// const RoomsPage = React.lazy(() => import('./container/Rooms'));
// const ContactPage = React.lazy(() => import('./container/Contact'));
// const PolicyPage = React.lazy(() => import('./container/Policies'));
// const PressPage = React.lazy(() => import('./container/Press'));
// const ItineraryPage = React.lazy(() => import('./container/Itineraries'));

function App({ cmsData }) {
  const { 'core-data': coreData } = cmsData;

  return (
    <Suspense fallback={<Loader></Loader>}>
      <div className="App">
        <Navbar coreData={coreData} />
        <Switch>
          <Route path="/pages/:slug" component={() => <ThingsToDoTemplate />} />
          <Route path="/" component={() => <HomePage />} />
        </Switch>
        <Footer cmsData={coreData} />
      </div>
    </Suspense>
  );
}

export default CmsDataWrapper(App, ['core-data']);
// export default withRouter(App);
