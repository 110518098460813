import React, { createContext, useReducer, useContext } from "react";
import reducer, { initialState } from './reducer';

import * as a from './actions';
export const actions = a;

export const FavouriteContext = createContext();


function FavouriteProvider(props) {
    const [state, dispatch] = useReducer(reducer, initialState);

    const value = { state, dispatch };

    return <FavouriteContext.Provider value={value} {...props} />;
}

function useFavouriteContext() {
    return useContext(FavouriteContext);
}

export { FavouriteProvider, useFavouriteContext };
