/**
 * @module frame-l
 * @description
 * A custom element for augmenting image ratios
 * @property {string} ratio=16:9 The element's aspect ratio
 */
import './Frame.css';
export default class Frame extends HTMLElement {
    constructor() {
        super();
        this.render = () => {
            if (this.children.length !== 1) {
                console.warn('<frame-l> elements should have just one child element');
            }
            this.i = `Frame-${[this.ratio, this.ratioAboveSm, this.ratioAboveMd, this.ratioAboveLg, this.ratioAboveXl].join(';')}`;
            this.dataset.i = this.i;
            if (!document.getElementById(this.i)) {
                let ratio = this.ratio.split(':');
                let ratioAboveSm = this.ratioAboveSm.split(':');
                let ratioAboveMd = this.ratioAboveMd.split(':');
                let ratioAboveLg = this.ratioAboveLg.split(':');
                let ratioAboveXl = this.ratioAboveXl.split(':');
                let styleEl = document.createElement('style');
                styleEl.id = this.i;
                styleEl.innerHTML = `
            [data-i="${this.i}"] {
              padding-bottom: calc(${ratio[1]} / ${ratio[0]} * 100%);
            }

            @media screen and (min-width: 640px) {
                [data-i="${this.i}"] {
                    padding-bottom: calc(${ratioAboveSm[1]} / ${ratioAboveSm[0]} * 100%);
                }
            }

            @media screen and (min-width: 768px) {
                [data-i="${this.i}"] {
                    padding-bottom: calc(${ratioAboveMd[1]} / ${ratioAboveMd[0]} * 100%);
                }
            }

            @media screen and (min-width: 1024px) {
                [data-i="${this.i}"] {
                    padding-bottom: calc(${ratioAboveLg[1]} / ${ratioAboveLg[0]} * 100%);
                }
            }

            @media screen and (min-width: 1280px) {
                [data-i="${this.i}"] {
                    padding-bottom: calc(${ratioAboveXl[1]} / ${ratioAboveXl[0]} * 100%);
                }
            }
            `.replace(/\s\s+/g, ' ').trim();
                document.head.appendChild(styleEl);
            }
        }
    }

    get ratio() {
        return this.getAttribute('ratio') || '16:9';
    }

    set ratio(val) {
        return this.setAttribute('ratio', val);
    }

    get ratioAboveSm() {
        return this.getAttribute('ratioAboveSm') || this.ratio;
    }

    set ratioAboveSm(val) {
        return this.setAttribute('ratioAboveSm', val);
    }

    get ratioAboveMd() {
        return this.getAttribute('ratioAboveMd') || this.ratioAboveSm;
    }

    set ratioAboveMd(val) {
        return this.setAttribute('ratioAboveMd', val);
    }

    get ratioAboveLg() {
        return this.getAttribute('ratioAboveLg') || this.ratioAboveMd;
    }

    set ratioAboveLg(val) {
        return this.setAttribute('ratioAboveLg', val);
    }

    get ratioAboveXl() {
        return this.getAttribute('ratioAboveXl') || this.ratioAboveLg;
    }

    set ratioAboveXl(val) {
        return this.setAttribute('ratioAboveXl', val);
    }

    static get observedAttributes() {
        return ['ratio', 'ratioAboveSm', 'ratioAboveMd', 'ratioAboveLg', 'ratioAboveXl'];
    }

    connectedCallback() {
        this.render();
    }

    attributeChangedCallback() {
        this.render();
    }
}

if ('customElements' in window) {
    customElements.define('frame-l', Frame);
}